import './App.css';

function App() {
  return (
    <div className="custom">
      <img src="image1.png" className="w-100% h-100%" alt="logo" />
    </div>
  );
}

export default App;
